'use client'

import { SignIn } from "@clerk/nextjs";
import { Suspense } from 'react'

function SignInContent() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <SignIn 
        appearance={{
          elements: {
            rootBox: "mx-auto",
            card: "bg-white shadow-lg rounded-lg",
            formButtonPrimary: "bg-primary hover:bg-primary/80",
            socialButtonsBlockButton: "hover:bg-gray-100",
            formFieldInput: "rounded-md border-gray-300 focus:border-primary focus:ring-primary",
            footer: "hidden"
          }
        }}
        afterSignInUrl="/dashboard"
        signUpUrl="/sign-up"
      />
    </div>
  );
}

export default function SignInPage() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Suspense fallback={<div>Loading...</div>}>
        <SignInContent />
      </Suspense>
    </div>
  );
} 